/* globals $ */

require('./console-support');

const moment = require('moment');

$('.profiles__toggle').click((e) => {
  e.preventDefault();
  $(e.currentTarget).parent().toggleClass('profiles--open');
});

$('.about__cta').click((e) => {
  e.preventDefault();
  document.querySelector('body').classList.add('cv');
});

new WOW().init();

// ** International number verification
var telInput = $('#phone');

telInput.intlTelInput({
  initialCountry: 'BE',
  preferredCountries: ['BE', 'NL', 'LU', 'GB', 'FR', 'DE'],
  utilsScript: '/vendor/int-tel/utils.js'
});

// ** Contact form

$('form').submit((e) => {
  e.preventDefault();
  let invalidMessage = $('.footer__validation-error');
  invalidMessage.hide();
  let $form = $(e.currentTarget);
  let value = telInput.intlTelInput('getNumber');
  let tgt = $form.attr('action');
  tgt += '?nummer=' + encodeURIComponent(value);
  $.getJSON(tgt, () => { console.log('sent'); });
  $('.footer__contact').addClass('footer__contact--sent');
});

// ** Photo animation

const pixelThreshold = 200;
const $window = $(window);
var prevCell, cell, position, $el;

var nextImage = () => {
  $('.bio__portrait .circle-image:last-child').each((i, el) => {
    $el = $(el);
    $el.parent().prepend($el);
  });
};

var loop = () => {
  position = $window.scrollTop();
  cell = (position - (position % pixelThreshold)) / pixelThreshold;
  if (cell !== prevCell) {
    prevCell = cell;
    nextImage();
  }

  window.requestAnimationFrame(loop);
};
window.requestAnimationFrame(loop);


// ** CV FORM
// ** International number verification
var telInput = $('#cv-phone');

telInput.intlTelInput({
  initialCountry: 'BE',
  preferredCountries: ['BE', 'NL', 'LU', 'GB', 'FR', 'DE'],
  utilsScript: '/vendor/int-tel/utils.js'
});


$('#cv-send').click((e) => {
  e.preventDefault();
  var container = document.querySelector('.overlay__content');
  container.setAttribute('class', 'overlay__content');

  var dateOfBirth = moment($('#cv-dateofbirth').val(), "DD/MM/YYYY");
  dateOfBirth = dateOfBirth.isValid() ? dateOfBirth.toDate() : null;

  var data = {
    firstname: $('#cv-firstname').val(),
    lastname: $('#cv-lastname').val(),
    email: $('#cv-email').val(),
    phone: $('#cv-phone').intlTelInput('getNumber'),
    language: $('#cv-language').val(),
    memo: $('#cv-memo').val(),
    location: $('#cv-location').val(),
    dateOfBirth
  }

  var isValid = true;
  if (!data.firstname) isValid = false;
  if (!data.lastname) isValid = false;
  if (!data.email) isValid = false;
  if (!data.phone) isValid = false;
  if (!data.location) isValid = false;

  if (!isValid) {
    container.setAttribute('class', 'overlay__content overlay__content--invalid');
    return;
  }

  container.setAttribute('class', 'overlay__content overlay__content--sending');

  // Get the input form and convert to base64
  var fileInput = document.querySelector('input[type="file"]');
  if (fileInput.files.length > 0) {
    var file = fileInput.files[0];
    data.filename = file.name;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      data.file = reader.result.split('base64,')[1];
      storeForm(data, container);
    };
  } else {
    storeForm(data, container);
  }
});

const storeForm = (data, container) => {
  const url = 'https://us-central1-senses-hunter.cloudfunctions.net/uploadCV';

  console.log('Posting');
  console.log(data);

  fetch(url, {
    method: "POST", 
    mode: "cors",
    cache: "no-cache",
    headers: { "Content-Type": "application/json", },
    body: JSON.stringify(data)
  }).then(response => response.json())
  .then(response => {
    container.setAttribute('class', 'overlay__content overlay__content--thanks');
    console.log(response);
  });
}